export function displayToast(
  text: string,
  optional?: { variant?: 'alert' | 'warning' | 'success' | 'info'; close?: boolean; title?: string }
) {
  const toastElement = getToastElement(text, optional);

  // Get toastContainer and add toastElement itself as child
  const toastContainer = document.querySelector('.gv-toast-container');
  if (!toastContainer) {
    return;
  }
  toastContainer.appendChild(toastElement);

  // Hanlde adding toastElement animation and make toast visible (missing from documentation)
  setTimeout(() => {
    toastElement.classList.add('gv-visible');
  }, 60);

  const autoRemoveTimeout = setTimeout(() => removeToast(toastContainer, toastElement), 4000);

  if (optional?.close === undefined || optional.close === true) {
    // Implement close button function
    const closeButton = toastElement.querySelector('.gv-toast-close');
    if (!closeButton) {
      return;
    }
    closeButton.addEventListener('click', () => {
      clearTimeout(autoRemoveTimeout);
      removeToast(toastContainer, toastElement);
    });
  }
}

function getToastElement(
  text: string,
  optional?: { variant?: 'alert' | 'warning' | 'success' | 'info'; close?: boolean; title?: string }
) {
  const button =
    optional?.close === undefined || optional.close === true
      ? `
    <button class="gv-toast-close">
      <gv-icon src="/src/icons/close.svg" />
    </button>
  `
      : '';

  const content = optional?.title
    ? `
      <div class="gv-toast-content">
        <div class="gv-toast-title">${optional.title}</div>
        <p>${text}</p>
      </div>
    `
    : `<p class="gv-toast-content">${text}</p>`;

  const toastElement = document.createElement('div');
  toastElement.innerHTML = content.concat(button);
  toastElement.classList.add('gv-toast', `gv-toast-${optional?.variant || 'success'}`);

  return toastElement;
}

function removeToast(toastContainer: Element, toastElement: Element) {
  toastElement.classList.add('gv-remove');
  setTimeout(() => {
    toastContainer.removeChild(toastElement);
  }, 250);
}
