import { ReactNode } from 'react';
//
import { AccessTypeEnum, UserRoleEnum } from './user';

// ----------------------------------------------------------------------

export type LayoutState = {
  showNewFeatureModal: boolean; // Keep track of whether or not this is user log in (only after login this can be set to true, else will always be false)
  lastReadFeatureVersion: number; // Keep track of last read feature version for this user (if the modal is not showed in the first place, it will always be default - 1.0)
  displayFeatureArr: FeatureReleaseDetails[];
  //
  headerHeight: number;
  footerHeight: number;
  sidebarHighlightActivePage: boolean; // Set to false whenever the current active page need to be UNhighlighted in the sidebar
  tabConfig: PageDetailsTabConfig | undefined; // Set whenever navigate to a page with sidebar
  //
  showActionsInProgressBanner: boolean;
  //
  enableScrollLock: boolean;
  overflowPadding: number; // !== 0 when enabling scroll lock
};

export type FeatureReleaseDetails = {
  name: string;
  version: number;
  releaseDate: Date; // Year - Month (index 0 - 11) - Day
  text: {
    title: string;
    description: string;
  };
};

export type NavLinkDetails = {
  title: string;
  path: string;
  icon: string;
};

export type TabNavLinkDetails = {
  title: string;
  tabName: string;
  icon: string;
};

export type PageDetailsTabConfig = {
  primaryPathname: string;
  title: string;
  navConfig: TabNavLinkDetails[] | SiteTabNavLinkDetails[];
};

export type SiteTabNavLinkDetails = TabNavLinkDetails & {
  restrictedUserRoles?: UserRoleEnum[];
  restrictedSiteRoles?: AccessTypeEnum[];
  restrictedForHostnetCustomer?: boolean;
};

export type TabDetails = {
  tabName: string;
  component: ReactNode;
};

export type TableHeader = {
  id: string;
  label: string; // Empty label = Empty string
  sort?: boolean;
};

export enum ProgressStepStatusEnum {
  WAITING = 'waiting',
  COMPLETED = 'completed',
  IN_PROGRESS = 'inProgress',
}
