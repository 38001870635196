import { useNavigate } from 'react-router';
// @types
import { BackupDetails, BackupStatus } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { fShortDate, fShortDateTime } from 'src/utils/format';
// components
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Button from 'src/components/gravity/Button';
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type Props = {
  backupDetails: BackupDetails;
};

// ----------------------------------------------------------------------

export default function BackupFinished({ backupDetails }: Props) {
  const navigate = useNavigate();

  // HOOK
  const { translate, currentLang } = useLocales();

  // EVENT FUNCTION
  function handleVisitSite() {
    navigate(
      `/sites/overview/${backupDetails.settings.cluster}/${backupDetails.settings.namespace}/${backupDetails.settings.hostname}`
    );
  }

  return (
    <>
      <p className="gv-heading-lg gv-mb-fluid">
        {translate('wpone.sites.details.backups.details.finished.title')}
      </p>

      <div className="gv-flex gv-flex-column-lg">
        <Alert
          type={backupDetails.currentStatus === BackupStatus.COMPLETED ? 'success' : 'alert'}
          text={translate(
            `wpone.sites.details.backups.details.finished.alert.${
              backupDetails.currentStatus === BackupStatus.COMPLETED ? 'completed' : 'failed'
            }`
          )}
        />

        <div className="gv-flex-column-md">
          <p className="gv-text-lg gv-text-bold">
            {translate(
              `wpone.sites.details.backups.details.info.title.${backupDetails.settings.method}`,
              {
                date: fShortDate(backupDetails.settings.date, currentLang.value),
                siteName: backupDetails.settings.hostname,
              }
            )}
          </p>

          <div className="gv-content-container gv-p-lg gv-stack-space-md">
            <div className="gv-flex gv-flex-col" style={{ gap: '4px' }}>
              <BackupInfo
                text={translate('wpone.sites.details.backups.details.info.started', {
                  dateTime: fShortDateTime(backupDetails.startedAt, currentLang.value),
                })}
              />

              <BackupInfo
                text={translate('wpone.sites.details.backups.details.info.ended', {
                  dateTime: fShortDateTime(backupDetails.endedAt || new Date(), currentLang.value),
                })}
              />
            </div>

            <ButtonGroup>
              <Button
                uiType="cancel"
                text={translate('wpone.sites.details.backups.details.finished.action.back')}
                onClick={() => navigate('/sites')}
              />

              <Button
                text={translate('wpone.sites.details.backups.details.finished.action.viewSite')}
                onClick={handleVisitSite}
              />
            </ButtonGroup>
          </div>
        </div>
      </div>
    </>
  );
}

// ----------------------------------------------------------------------

type BackupInfoProps = {
  text: string;
};

// ----------------------------------------------------------------------

function BackupInfo({ text }: BackupInfoProps) {
  return (
    <div
      className="gv-flex-row-md gv-justify-between gv-py-sm gv-px-md gv-radius"
      style={{ backgroundColor: 'var(--color-surface-dim)' }}
    >
      <p className="gv-text-sm">{text}</p>
    </div>
  );
}
