import { useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { toggleActionsInProgressBanner } from 'src/redux/features/layout';
import { setCurrentMigrationDetails } from 'src/redux/features/migration';
// @mui
import { IconButton, useTheme } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { fShortDateTime, fHttpPrefix } from 'src/utils/format';
// components
import MaterialIcon from 'src/components/MaterialIcon';
import AlertBanner from 'src/components/AlertBanner';
import { TextWithNavigationLink } from 'src/components/text';

// ----------------------------------------------------------------------

export default function ActionsInProgressBanner() {
  const dispatch = useDispatch();

  const theme = useTheme();

  const isExtraSmall = useResponsive('down', 'sm');

  // HOOK
  const { translate, currentLang } = useLocales();

  // STATE
  const { overflowPadding } = useSelector((state) => state.layout);

  const { migrationsInProgress } = useSelector((state) => state.migration);

  const { backupsInProgress } = useSelector((state) => state.backup);

  //
  useEffect(() => {
    if (migrationsInProgress.length === 0 && backupsInProgress.length === 0) {
      dispatch(toggleActionsInProgressBanner(false));
    }
  }, [dispatch, migrationsInProgress, backupsInProgress]);

  return (
    <AlertBanner
      severity="info"
      action={
        <IconButton
          onClick={() => dispatch(toggleActionsInProgressBanner(false))}
          sx={{ pr: isExtraSmall ? `${8 + overflowPadding}px` : '8px' }}
        >
          <MaterialIcon icon="close" />
        </IconButton>
      }
      sx={{
        paddingRight: `${24 + overflowPadding}px`,
      }}
    >
      {!window.location.pathname.startsWith('/sites/migration') &&
        migrationsInProgress.map((migrationDetails) => (
          <TextWithNavigationLink
            key={migrationDetails.destinationSite}
            text={translate('wpone.sites.migration.bannerText', {
              sourceUrl: fHttpPrefix(migrationDetails.sourceSite),
              destinationUrl: migrationDetails.destinationSite,
            })}
            navigateTo={`/sites/migration`}
            onClick={() => {
              dispatch(setCurrentMigrationDetails(migrationDetails));
            }}
            textSx={{ ...theme.typography.body2 }}
            linkSx={{
              pl: 0.5,
              fontWeight: 600,
              textDecoration: 'underline',
              color: theme.palette.text.primary,
            }}
          />
        ))}

      {!window.location.pathname.startsWith('/sites/backups') &&
        backupsInProgress.map(({ settings }) => (
          <TextWithNavigationLink
            key={settings.hostname}
            text={translate(`wpone.sites.details.backups.banner.${settings.method}`, {
              date: fShortDateTime(settings.date, currentLang.value),
              siteName: settings.hostname,
            })}
            navigateTo={`/sites/backups/${settings.cluster}/${settings.namespace}/${settings.hostname}/details`}
            textSx={{ ...theme.typography.body2 }}
            linkSx={{
              pl: 0.5,
              fontWeight: 600,
              textDecoration: 'underline',
              color: theme.palette.text.primary,
            }}
          />
        ))}
    </AlertBanner>
  );
}
