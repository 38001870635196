import { createApi } from '@reduxjs/toolkit/query/react';
// @types
import { ManualUpdateResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
import { OverallUpdateManagerDataResponse } from 'src/@types/data';
import { CompletedUpdatesResponse } from 'src/@types/site';
// utils
import { convertToIDN } from 'src/utils/convert';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------

export const updateApi = createApi({
  reducerPath: 'updateApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v5/updates' }),
  tagTypes: ['Updates'],
  endpoints: (builder) => ({
    // GET UPDATE HISTORY
    getUpdates: builder.query<
      { message: string; data: CompletedUpdatesResponse },
      string | undefined
    >({
      query: (siteName) => ({
        url: `/${convertToIDN(siteName)}/completed-updates`,
        method: 'get',
      }),
      providesTags: (result) => (result ? [{ type: 'Updates', id: 'UPDATE-LIST' }] : []),
    }),
    //MANUALLY TRIGGER WP UPDATES
    triggerUpdates: builder.mutation<ManualUpdateResponse, string>({
      query: (siteName: string) => ({
        url: `/${convertToIDN(siteName)}/trigger`,
        method: 'post',
      }),
      // No need for invalidateTags since this endpoint only trigger enqueue process
    }),
    // GET OVERALL UPDATE MANAGER DATA
    getOverallUpdateManagerData: builder.query<OverallUpdateManagerDataResponse, void>({
      query: () => ({
        url: `/stats`,
        method: 'get',
      }),
    }),
  }),
});

export const {
  useGetUpdatesQuery,
  useTriggerUpdatesMutation,
  useGetOverallUpdateManagerDataQuery,
} = updateApi;
