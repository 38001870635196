import { ReactNode, useEffect } from 'react';
// redux
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

type Props = {
  title?: string; // This will be removed later
  open: boolean;
  onClose: VoidFunction;
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function ActionDialog({ open, onClose, children }: Props) {
  // STATE
  const showNewFeatureModal = useSelector((state) => state.layout.showNewFeatureModal);

  //
  // Temp solution until Menupopover is converted to Gravity
  // Setting body position to fixed when modal is open, this makes the background not scrollable because it's not supposed to move
  useEffect(() => {
    if (open || showNewFeatureModal) {
      document.body.style.position = 'fixed';
    }
    return () => {
      document.body.style.position = '';
    };
  }, [open, showNewFeatureModal]);

  return (
    <div className="gv-activated">
      {open && (
        <div className="gv-modal" style={{ zIndex: 1300 }}>
          <div className="gv-modal-content">
            <button className="gv-modal-close" onClick={onClose}>
              <gv-icon src="/src/icons/close.svg" />
            </button>
            {children}
          </div>
        </div>
      )}
    </div>
  );
}
