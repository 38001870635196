import { createApi } from '@reduxjs/toolkit/query/react';
// @types
import {
  AccessPermissionGetListResponse,
  ShareResourcePermissionsRequest,
  RevokeAccessDTO,
  ChangeRoleDTO,
  AccessPermissionGetListWithRevokableResponse,
  RevokeZoneAccessDTO,
  TransferOwnershipCreateRequest,
  AddAdminPermissionRequest,
  UserMainDataDTO,
  ResourceGetResponse,
  ImportOwnerRequest,
} from '@joonasvanhatapio/wp-cloud-backend-types';
// utils
import { convertToIDN } from 'src/utils/convert';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------

export const resourceApi = createApi({
  reducerPath: 'resourceApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v5/resource' }),
  tagTypes: ['Resources'],
  endpoints: (builder) => ({
    // GET SITE PERMISSIONS
    getSitePermissions: builder.query<
      AccessPermissionGetListResponse,
      {
        siteId: string;
        rowsPerPage: number;
        page: number;
        accessType: string;
        sortType: string;
        sortField: string;
      }
    >({
      query: ({ rowsPerPage, page, accessType, sortType, sortField, siteId }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('limit', rowsPerPage.toString());
        searchParams.append('page', page.toString());
        searchParams.append('sortType', sortType);

        if (accessType !== 'all') {
          searchParams.append('accessType', accessType);
        }
        searchParams.append('sortField', sortField);

        const searchParamsString = searchParams.toString();

        return {
          url: `/site/${siteId}?${searchParamsString}`,
          method: 'get',
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: 'Resources' as const, id: id })),
              { type: 'Resources', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Resources', id: 'PARTIAL-LIST' }],
    }),
    // GET ZONE PERMISSIONS
    getZonePermissions: builder.query<
      AccessPermissionGetListWithRevokableResponse,
      {
        rowsPerPage: number;
        name: string | undefined;
        page: number;
        accessType: string;
        sortType: string;
        sortField: string;
      }
    >({
      query: ({ name, rowsPerPage, page, accessType, sortType, sortField }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('limit', rowsPerPage.toString());
        searchParams.append('page', page.toString());
        searchParams.append('sortType', sortType);

        if (accessType !== 'all') {
          searchParams.append('accessType', accessType);
        }
        if (sortField !== 'email') {
          searchParams.append('sortField', sortField);
        }

        const searchParamsString = searchParams.toString();

        return { url: `/zone/${convertToIDN(name)}?${searchParamsString}`, method: 'get' };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: 'Resources' as const, id: id })),
              { type: 'Resources', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Resources', id: 'PARTIAL-LIST' }],
    }),
    // IMPORT OWNER
    importOwner: builder.mutation<ResourceGetResponse, ImportOwnerRequest>({
      query: (data) => ({
        url: `/import-owner`,
        method: 'post',
        data: {
          ...data,
          externalId: convertToIDN(data.externalId),
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Resources', id: 'PARTIAL-LIST' }] : []),
    }),
    // ADD SITE USER
    addSiteUser: builder.mutation<UserMainDataDTO, ShareResourcePermissionsRequest>({
      query: (data) => ({
        url: `/share-site-resource`,
        method: 'post',
        data: data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Resources', id: 'PARTIAL-LIST' }] : []),
    }),
    // ADD ZONE USER
    addZoneUser: builder.mutation<UserMainDataDTO, AddAdminPermissionRequest>({
      query: (data) => ({
        url: `/share-zone-resource`,
        method: 'post',
        data: {
          ...data,
          zoneName: convertToIDN(data.zoneName),
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Resources', id: 'PARTIAL-LIST' }] : []),
    }),
    // CHANGE SITE USER ROLE
    changeSiteUserRole: builder.mutation<{ success: boolean; message: string }, ChangeRoleDTO>({
      query: (data) => ({
        url: `/change-access`,
        method: 'post',
        data: data,
      }),
      invalidatesTags: (result) =>
        result?.success ? [{ type: 'Resources', id: 'PARTIAL-LIST' }] : [],
    }),
    // REVOKE SITE USER
    revokeSiteUser: builder.mutation<{ success: boolean; message: string }, RevokeAccessDTO>({
      query: (data) => ({
        url: `/revoke-site-access`,
        method: 'post',
        data: data,
      }),
      invalidatesTags: (result) =>
        result?.success ? [{ type: 'Resources', id: 'PARTIAL-LIST' }] : [],
    }),
    // REVOKE ZONE USER
    revokeZoneUser: builder.mutation<{ success: boolean; message: string }, RevokeZoneAccessDTO>({
      query: (data) => ({
        url: `/revoke-zone-access`,
        method: 'post',
        data: {
          ...data,
          zoneName: convertToIDN(data.zoneName),
        },
      }),
      invalidatesTags: (result) =>
        result?.success ? [{ type: 'Resources', id: 'PARTIAL-LIST' }] : [],
    }),
    // TRANSFER SITE OWNERSHIP
    trasferSiteOwnership: builder.mutation<
      { success: boolean; message: string },
      {
        siteId: string;
        transferData: TransferOwnershipCreateRequest;
      }
    >({
      query: ({ siteId, transferData }) => ({
        url: `/site/transfer-ownership/${siteId}`,
        method: 'post',
        data: transferData,
      }),
      invalidatesTags: (result) =>
        result?.success ? [{ type: 'Resources', id: 'PARTIAL-LIST' }] : [],
    }),
    // TRANSFER ZONE OWNERSHIP
    trasferZoneOwnership: builder.mutation<
      { success: boolean; message: string },
      {
        zoneName: string | undefined;
        transferData: TransferOwnershipCreateRequest;
      }
    >({
      query: ({ zoneName, transferData }) => ({
        url: `/zone/transfer-ownership/${convertToIDN(zoneName)}`,
        method: 'post',
        data: transferData,
      }),
      invalidatesTags: (result) =>
        result?.success ? [{ type: 'Resources', id: 'PARTIAL-LIST' }] : [],
    }),
  }),
});

export const {
  useGetSitePermissionsQuery,
  useGetZonePermissionsQuery,
  useImportOwnerMutation,
  useAddSiteUserMutation,
  useAddZoneUserMutation,
  useChangeSiteUserRoleMutation,
  useRevokeSiteUserMutation,
  useRevokeZoneUserMutation,
  useTrasferSiteOwnershipMutation,
  useTrasferZoneOwnershipMutation,
} = resourceApi;
