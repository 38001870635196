import { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router';
// redux
import { useGetUpdatesQuery } from 'src/redux/api/updateApi';
// @types
import { TableHeader } from 'src/@types/layout';
import { UpdateReportInfo } from 'src/@types/site';
// hooks
import useGravityTable from 'src/hooks/useGravityTable';
import useLocales from 'src/hooks/useLocales';
// utils
import { fShortDateTime } from 'src/utils/format';
// components
import { TableHeadCustom, TablePagination, TableSkeleton } from 'src/components/gravity/table';
//
import UpdateTableRow from './UpdateTableRow';

// ----------------------------------------------------------------------

const HEADERS: TableHeader[] = [
  {
    id: 'date',
    label: 'wpone.sites.details.updates.updateListing.table.header.date',
  },
  {
    id: 'status',
    label: 'wpone.sites.details.updates.updateListing.table.header.status',
  },
  {
    id: 'message',
    label: 'wpone.sites.details.updates.updateListing.table.header.message',
  },
  {
    id: 'action',
    label: '',
  },
];

// ----------------------------------------------------------------------

type Props = {
  setCurrentUpdateReportInfo: Dispatch<SetStateAction<UpdateReportInfo | undefined>>;
};

// ----------------------------------------------------------------------

export default function UpdateTable({ setCurrentUpdateReportInfo }: Props) {
  const { name } = useParams();

  // HOOK
  const { translate, currentLang } = useLocales();

  const {
    page,
    rowsPerPage,
    //
    onChangePage,
  } = useGravityTable({
    defaultRowsPerPage: 10,
  });

  // API
  const { data, isFetching } = useGetUpdatesQuery(name);

  // VAR
  const filteredData = data?.data.updateList || [];
  // TESTING CODE
  // const filteredData =
  //   data !== undefined
  //     ? [
  //         ...data.data.updateList.filter((update) => update.updated_wp !== null).slice(0, 3),
  //         ...data.data.updateList.filter((update) => {
  //           const updates = update.updated_themes;

  //           if (updates === null) return false;
  //           else {
  //             return Object.keys(updates).some((key: string) => updates[key].visual_diffs !== null);
  //           }
  //         }),
  //         ...data.data.updateList.filter((update) => {
  //           const updates = update.updated_plugins;

  //           if (updates === null) return false;
  //           else {
  //             return Object.keys(updates).some((key: string) => updates[key].visual_diffs !== null);
  //           }
  //         }),
  //       ].reduce<UpdateReport[]>((reports, current) => {
  //         if (!reports.find((report) => report.id === current.id)) {
  //           reports.push(current);
  //         }
  //         return reports;
  //       }, [])
  //     : [];
  // const filteredData: UpdateReport[] = DUMMY_UPDATES_DATA.updateList;

  const isNotFound = filteredData.length === 0;

  return (
    <div className="gv-data-table">
      <div style={{ overflowX: 'auto' }}>
        <table className={!isFetching && isNotFound ? '' : 'gv-col-2-shrink gv-col-4-shrink'}>
          <TableHeadCustom headers={HEADERS} />
          <tbody>
            {!isFetching
              ? filteredData
                  .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                  .map((row) => (
                    <UpdateTableRow
                      key={row.id}
                      row={row}
                      setCurrentUpdateReportInfo={setCurrentUpdateReportInfo}
                    />
                  ))
              : [...Array(rowsPerPage)].map((_, index) => (
                  <TableSkeleton key={index} headers={HEADERS} />
                ))}
          </tbody>
        </table>
      </div>

      {!isFetching && isNotFound && (
        <div className="gv-empty-state">
          <div className="gv-empty-state-content">
            <h3 className="gv-title">
              {translate('wpone.sites.details.updates.updateListing.table.noData.title')}
            </h3>
            <p>
              {data?.data.nextUpdate
                ? translate(
                    'wpone.sites.details.updates.updateListing.table.noData.description.nextUpdateAvailable',
                    {
                      dateTime: fShortDateTime(
                        new Date(data.data.nextUpdate as string),
                        currentLang.value
                      ),
                    }
                  )
                : translate(
                    'wpone.sites.details.updates.updateListing.table.noData.description.nextUpdateNotAvailable'
                  )}
            </p>
          </div>
        </div>
      )}

      <TablePagination
        totalRows={filteredData.length}
        currentPage={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
      />
    </div>
  );
}
