// @types
import { MWPSiteIssueEnum, MWPSiteStatusDetails, MWPSiteStatusEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  statusDetails: MWPSiteStatusDetails;
};

// ----------------------------------------------------------------------

export default function StatusBlock({ statusDetails }: Props) {
  // HOOK
  const { translate } = useLocales();

  // HELPER FUNCTION
  function getSiteStatusDisplay() {
    const { status, issue } = statusDetails;

    // Incomplete status will not be checked here
    // Writing logic as below so that in the unlikely event when status and issue don't align, the text is still displayed correctly
    if (status === MWPSiteStatusEnum.SUSPENDED || status === MWPSiteStatusEnum.MIGRATING) {
      return status;
    } else {
      switch (issue) {
        case MWPSiteIssueEnum.SSL:
        case MWPSiteIssueEnum.REST_API:
        case MWPSiteIssueEnum.REDIRECTION:
          return 'actionNeeded';
        default:
          return status;
      }
    }
  }

  return (
    <div className="gv-flex gv-flex-row">
      <gv-icon src="/src/icons/vitals.svg" class="gv-text-on-alternative" />
      <p className="gv-text-sm gv-text-bold gv-text-on-alternative" style={{ marginInline: '4px' }}>
        {translate('dashboard.sites.details.mwp.overview.status.description', {
          status: translate(
            `dashboard.sites.details.mwp.overview.status.${getSiteStatusDisplay()}`
          ),
        })}
      </p>
    </div>
  );
}
