import { Dispatch, SetStateAction } from 'react';
// @types
import { TableHeader } from 'src/@types/layout';
import {
  UpdateDetails,
  UpdateDetailsInfo,
  UpdateDetailsTypeEnum,
  UpdateReport,
} from 'src/@types/site';
// components
import { TableHeadCustom } from 'src/components/gravity/table';
//
import UpdateDetailsTableRow from './UpdateReportTableRow';

// ----------------------------------------------------------------------

const HEADERS: TableHeader[] = [
  {
    id: 'name',
    label: 'wpone.sites.details.updates.updateReport.table.header.name',
  },
  {
    id: 'status',
    label: 'wpone.sites.details.updates.updateReport.table.header.status',
  },
  {
    id: 'type',
    label: 'wpone.sites.details.updates.updateReport.table.header.type',
  },
  {
    id: 'version',
    label: 'wpone.sites.details.updates.updateReport.table.header.version',
  },
  {
    id: 'action',
    label: '',
  },
];

// ----------------------------------------------------------------------

type Props = {
  currentUpdateReport: UpdateReport;
  setCurrentUpdateDetailsInfo: Dispatch<SetStateAction<UpdateDetailsInfo | undefined>>;
};

// ----------------------------------------------------------------------

export default function UpdateReportTable({
  currentUpdateReport,
  setCurrentUpdateDetailsInfo,
}: Props) {
  // VAR
  // There can be cases where updated_wp, updated_themes, updated_plugins is empty obj (which can be considered as null)
  const wpUpdatesArr: UpdateDetails[] =
    !currentUpdateReport.updated_wp || Object.keys(currentUpdateReport.updated_wp).length === 0
      ? []
      : [currentUpdateReport.updated_wp];
  const themesUpdatesArr: UpdateDetails[] =
    !currentUpdateReport?.updated_themes ||
    Object.keys(currentUpdateReport.updated_themes).length === 0
      ? []
      : Object.values(currentUpdateReport.updated_themes);
  const pluginsUpdatesArr: UpdateDetails[] =
    !currentUpdateReport?.updated_plugins ||
    Object.keys(currentUpdateReport.updated_plugins).length === 0
      ? []
      : Object.values(currentUpdateReport.updated_plugins);

  const filteredData: { type: UpdateDetailsTypeEnum; details: UpdateDetails }[] = [
    ...wpUpdatesArr.map((update) => ({ type: UpdateDetailsTypeEnum.CORE, details: update })),
    ...themesUpdatesArr.map((update) => ({ type: UpdateDetailsTypeEnum.THEME, details: update })),
    ...pluginsUpdatesArr.map((update) => ({ type: UpdateDetailsTypeEnum.PLUGIN, details: update })),
  ];

  return (
    <div
      className="gv-data-table"
      style={{
        overflowX: 'auto',
      }}
    >
      <table className="gv-col-5-text-right">
        <TableHeadCustom headers={HEADERS} />
        <tbody>
          {filteredData.map((row) => (
            <UpdateDetailsTableRow
              key={`${row.type}${row.type === 'core' ? '' : row.details.title}`}
              row={row}
              setCurrentUpdateDetailsInfo={setCurrentUpdateDetailsInfo}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
