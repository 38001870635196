import ReactGA from 'react-ga4';
// redux
import { useDispatch } from 'src/redux/store';
import { changeBackupStatus } from 'src/redux/features/backup';
import { useCancelRestorationMutation } from 'src/redux/api/backupApi';
// @types
import { BackupDetails, BackupStatus } from 'src/@types/site';
import { BadRequestValidationResponseDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { fShortDateTime } from 'src/utils/format';
import { displayToast } from 'src/utils/handleToast';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type Props = {
  backupDetails: BackupDetails;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function AbortBackupConfirmation({ backupDetails, onClose }: Props) {
  const dispatch = useDispatch();

  // HOOK
  const { translate, currentLang } = useLocales();

  // API
  const [cancelRestoration, { isLoading }] = useCancelRestorationMutation();

  // EVENT FUNCTION
  function cancelRestorationHandler() {
    cancelRestoration({ hostname: backupDetails.settings.hostname, backupId: backupDetails.id })
      .unwrap()
      .then((response) => {
        ReactGA.event({
          category: 'button',
          action: 'click',
          label: 'cancel-restoration',
        });
        dispatch(
          changeBackupStatus({
            hostname: backupDetails.settings.hostname,
            newStatus: BackupStatus.ABORTED,
            endedAt: response.cancelledAt ? (response.cancelledAt as Date) : new Date(),
          })
        );
        onClose();
      })
      .catch((error) => {
        let errCode = (error as BadRequestValidationResponseDTO).statusCode;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
      });
  }

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate(
            'wpone.sites.details.backups.details.inProgress.cancelRestorationDialog.title'
          )}
        </h2>

        <p style={{ overflowX: 'auto' }}>
          {translate(
            `wpone.sites.details.backups.details.inProgress.cancelRestorationDialog.description.${backupDetails.settings.method}`,
            {
              date: fShortDateTime(backupDetails.settings.date, currentLang.value),
              siteName: backupDetails.settings.hostname,
            }
          )}
        </p>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button
          text={translate(
            'wpone.sites.details.backups.details.inProgress.cancelRestorationDialog.action.continue'
          )}
          uiType="cancel"
          onClick={onClose}
        />
        <Button
          text={translate(
            'wpone.sites.details.backups.details.inProgress.cancelRestorationDialog.action.cancel'
          )}
          onClick={cancelRestorationHandler}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
