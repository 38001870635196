import { useEffect, useState } from 'react';
// redux
import { useDispatch } from 'src/redux/store';
import { changeBackupStatus } from 'src/redux/features/backup';
import { useGetRestorationStatusQuery } from 'src/redux/api/backupApi';
// @types
import { BackupDetails, BackupStatus, BackupStep } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { fShortDate, fShortDateTime } from 'src/utils/format';
import getProgressStatus from 'src/utils/getProgressStatus';
// components
import Button from 'src/components/gravity/Button';
import ProgressStep from 'src/components/gravity/ProgressStep';

// ----------------------------------------------------------------------

type Props = {
  backupDetails: BackupDetails;
  toggleAbortBackupDialog: boolean;
  onOpenAbortBackupDialog: VoidFunction;
  onCloseAbortBackupDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function BackupInProgress({
  backupDetails,
  toggleAbortBackupDialog,
  onOpenAbortBackupDialog,
  onCloseAbortBackupDialog,
}: Props) {
  const dispatch = useDispatch();

  // HOOK
  const { translate, currentLang } = useLocales();

  // STATE
  const [backupCurrentStep, setBackupCurrentStep] = useState<BackupStep>(BackupStep.PREPARE);

  // API
  const { data: restoreStatusData, isFetching: restoreStatusIsFetching } =
    useGetRestorationStatusQuery(
      { hostname: backupDetails.settings.hostname, backupId: backupDetails.id },
      {
        pollingInterval: 3000,
      }
    );

  // VAR
  const backupSteps = Object.values(BackupStep);

  //
  useEffect(() => {
    if (restoreStatusIsFetching) return;

    if (!restoreStatusData?.status) return;

    let currentStep = restoreStatusData.status;

    if (currentStep === 'failed' || currentStep === 'completed') {
      // If restoration status changes when cancel restoration dialog is open, then close it
      if (toggleAbortBackupDialog) {
        onCloseAbortBackupDialog();
      }

      const endedAt = restoreStatusData.completedAt
        ? (restoreStatusData.completedAt as Date)
        : new Date();

      if (currentStep === 'completed') {
        dispatch(
          changeBackupStatus({
            hostname: backupDetails.settings.hostname,
            newStatus: BackupStatus.COMPLETED,
            endedAt: endedAt,
          })
        );
      } else {
        dispatch(
          changeBackupStatus({
            hostname: backupDetails.settings.hostname,
            newStatus: BackupStatus.FAILED,
            endedAt: endedAt,
          })
        );
      }
    } else {
      setBackupCurrentStep(currentStep as BackupStep);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restoreStatusIsFetching]);

  return (
    <>
      <div className="gv-flex-column-md gv-mb-fluid">
        <p className="gv-heading-lg">
          {translate('wpone.sites.details.backups.details.inProgress.title')}
        </p>

        <p className="gv-text-sm gv-text-max">
          {translate(`wpone.sites.details.backups.details.inProgress.description`, {
            email: backupDetails.notificationEmail,
          })}
        </p>
      </div>

      <div className="gv-flex-column-md">
        <div className="gv-flex gv-flex-col" style={{ gap: '4px' }}>
          <p className="gv-text-lg gv-text-bold">
            {translate(
              `wpone.sites.details.backups.details.info.title.${backupDetails.settings.method}`,
              {
                date: fShortDate(backupDetails.settings.date, currentLang.value),
                siteName: backupDetails.settings.hostname,
              }
            )}
          </p>
          <p className="gv-text-sm">
            {translate('wpone.sites.details.backups.details.info.started', {
              dateTime: fShortDateTime(backupDetails.startedAt, currentLang.value),
            })}
          </p>
        </div>

        <div className="gv-content-container gv-p-lg gv-stack-space-md">
          <div className="gv-flex gv-flex-col" style={{ gap: '4px' }}>
            {backupSteps.map((step) => (
              <ProgressStep
                key={step}
                status={getProgressStatus(step, backupCurrentStep, backupSteps)}
                text={`wpone.sites.details.backups.details.inProgress.step.${step}`}
              />
            ))}
          </div>

          {(backupCurrentStep === BackupStep.PREPARE || backupCurrentStep === BackupStep.FETCH) && (
            <Button
              text={translate('wpone.sites.details.backups.details.inProgress.action')}
              onClick={onOpenAbortBackupDialog}
            />
          )}
        </div>
      </div>
    </>
  );
}
