import { ReactNode, useEffect } from 'react';
import { useParams } from 'react-router';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import {
  removeChangedPlanSite,
  removeCreatedSite,
  removeDiskUpgradedSite,
} from 'src/redux/features/site';

// ----------------------------------------------------------------------

// Old site details structure: SiteDetailsGuard > SiteDetailsLayout > SiteDetails > DetailsPage (Overview, Statistics, Plan...)
// When revisiting a page, SiteDetailsLayout will be rendered twice because of setting up the tabConfig which leads to all nested page under it is rendered twice
// ==> The status banner cannot be handled inside these pages' return useEffect since it will be removed before user has a chance to see it
// This StatusBannerHandler is created as the wrapper of SiteDetailsLayout so that revisiting a page will not cause its return inside useEffect to be ran
// New site details structure to support: SiteDetailsGuard > StatusBannerHandler > SiteDetailsLayout > SiteDetails > DetailsPage

// NOTE: the term "revisiting a page" above means you at one page => go to another page => comeback OR at one page, changed some state to make the whole page rerender
// Opposite of this is refreshing/reloading => go to this page for the first time.
// Even go to a site details page => go to site listing => go to another site details page is counted as revisiting since the common layout is used on all site details page

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function StatusBannerHandler({ children }: Props) {
  const { name, tabname } = useParams();

  const dispatch = useDispatch();

  // STATE
  const { createdSites, diskUpgradedSites, planChangedSites } = useSelector((state) => state.site);

  // VAR
  const createdSite = createdSites.find((siteName) => siteName === name);

  const diskUpgradedSite = diskUpgradedSites.find((site) => site.siteName === name);

  const planChangedSite = planChangedSites.find((site) => site.siteName === name);

  //
  useEffect(
    () => () => {
      if (name) {
        if (createdSite && tabname === 'overview') {
          dispatch(removeCreatedSite(name));
        }

        if (diskUpgradedSite && tabname === diskUpgradedSite.fromTab) {
          dispatch(removeDiskUpgradedSite(name));
        }

        if (planChangedSite && tabname === planChangedSite.fromTab) {
          dispatch(removeChangedPlanSite(name));
        }
      }
    },
    [dispatch, name, tabname, createdSite, diskUpgradedSite, planChangedSite]
  );

  return <>{children}</>;
}
