import { ReactNode, useState } from 'react';

// ----------------------------------------------------------------------

type Position =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right';

type Props = {
  children: ReactNode;
  tooltip: string;
  width?: 'auto' | number;
  position?: Position;
  arrow?: Position;
};

// ----------------------------------------------------------------------

export default function Tooltip({
  children,
  tooltip,
  width = 'auto',
  position = 'top-center',
  arrow,
}: Props) {
  // STATE
  const [active, setActive] = useState(false);

  // VAR
  const verticalDirection = position.split('-')[0];
  const horizontalDirection = position.split('-')[1];

  return (
    <div
      style={{
        height: 'var(--size-icon-sm)',
        width: 'var(--size-icon-sm)',
        display: 'inline-block',
        position: 'relative',
        cursor: 'pointer',
      }}
      onMouseEnter={() => {
        if (active === false) setActive(true);
      }}
      onMouseLeave={() => {
        if (active === true) setActive(false);
      }}
    >
      {children}
      {active && (
        <div
          role="tooltip"
          className={`gv-tooltip${arrow ? ` gv-arrow-${arrow}` : ''}`}
          style={{
            position: 'absolute',
            top: verticalDirection === 'top' ? 'auto' : '100%',
            bottom: verticalDirection === 'bottom' ? 'auto' : '100%',
            right: horizontalDirection === 'right' ? '0' : 'auto',
            left:
              horizontalDirection === 'center'
                ? '50%'
                : horizontalDirection === 'left'
                ? '0'
                : 'auto',
            transform: horizontalDirection === 'center' ? 'translateX(-50%)' : 'translateX(0)',
            width: width === 'auto' ? width : `${width}px`,
            cursor: 'text',
            pointerEvents: 'none',
          }}
        >
          <p>{tooltip}</p>
        </div>
      )}
    </div>
  );
}
