import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { LighthouseStatEnum, LighthouseStatusEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import {
  convertLighthouseStatsDisplay,
  convertLighthouseStatusColorCodes,
  convertLighthouseStatusIcon,
} from 'src/utils/convert';
//
import PageSection from '../PageSection';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
};

// ----------------------------------------------------------------------

export default function LighthousePerformanceBlock({ site }: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // VAR
  const lighthouse_statistics = site?.lighthouse_statistics;

  // HELPER FUNCTION
  function getStats(lighthouseStat: LighthouseStatEnum) {
    switch (lighthouseStat) {
      case LighthouseStatEnum.performance:
        return {
          previousValue: lighthouse_statistics?.previous_performance.score,
          currentValue: lighthouse_statistics?.current_performance.score,
        };
      case LighthouseStatEnum.accessibility:
        return {
          previousValue: lighthouse_statistics?.previous_accessibility,
          currentValue: lighthouse_statistics?.current_accessibility,
        };
      case LighthouseStatEnum.seo:
        return {
          previousValue: lighthouse_statistics?.previous_seo,
          currentValue: lighthouse_statistics?.current_seo,
        };
      case LighthouseStatEnum.bestPractices:
        return {
          previousValue: lighthouse_statistics?.previous_best_practices,
          currentValue: lighthouse_statistics?.current_best_practices,
        };
      case LighthouseStatEnum.fcp:
        return {
          previousValue: lighthouse_statistics?.previous_performance.first_contentful_paint,
          currentValue: lighthouse_statistics?.current_performance.first_contentful_paint,
        };
      case LighthouseStatEnum.speedIndex:
        return {
          previousValue: lighthouse_statistics?.previous_performance.speed_index,
          currentValue: lighthouse_statistics?.current_performance.speed_index,
        };
      case LighthouseStatEnum.lcp:
        return {
          previousValue: lighthouse_statistics?.previous_performance.largest_contentful_paint,
          currentValue: lighthouse_statistics?.current_performance.largest_contentful_paint,
        };
      case LighthouseStatEnum.blocking:
        return {
          previousValue: lighthouse_statistics?.previous_performance.total_blocking_time,
          currentValue: lighthouse_statistics?.current_performance.total_blocking_time,
        };
      case LighthouseStatEnum.cls:
        return {
          previousValue: lighthouse_statistics?.previous_performance.cumulative_layout_shift,
          currentValue: lighthouse_statistics?.current_performance.cumulative_layout_shift,
        };
      default:
        return {
          previousValue: undefined,
          currentValue: undefined,
        };
    }
  }

  return (
    <PageSection
      title={translate('wpone.sites.details.statistics.lighthousePerformance.title')}
      description={translate('wpone.sites.details.statistics.lighthousePerformance.description')}
      action={{
        text: translate('wpone.sites.details.statistics.lighthousePerformance.action'),
        onClick: () => {
          ReactGA.event({
            category: 'button',
            action: 'click',
            label: 'learn-more-about-web-vitals',
          });
          window.open(`https://web.dev/measure/?url=https%3A%2F%2F${name}`, '_blank');
        },
        isLink: true,
        iconName: 'open_in_new',
      }}
    >
      <div className="gv-flex-column-md">
        <div className="gv-flex-column-md gv-tab-flex-row">
          {[
            LighthouseStatusEnum.unknown,
            LighthouseStatusEnum.alert,
            LighthouseStatusEnum.warning,
            LighthouseStatusEnum.good,
          ]
            .map((statusValue) => ({
              status: statusValue,
              ...convertLighthouseStatusIcon(statusValue),
            }))
            .map((statusInfo) => (
              <div key={statusInfo.status} className="gv-flex gv-flex-row" style={{ gap: '4px' }}>
                <span style={{ color: `var(${statusInfo.color})` }}>
                  <gv-icon src={`/src/icons/${statusInfo.iconName}.svg`} />
                </span>
                <p className="gv-text-sm">
                  {translate(
                    `wpone.sites.details.statistics.lighthousePerformance.colorCodes.${statusInfo.status}`
                  )}
                </p>
              </div>
            ))}
        </div>

        <div className={`gv-grid gv-grid-cols-${isMobile ? 1 : 2} gv-desk-grid-cols-3 gv-gap-lg`}>
          {[
            LighthouseStatEnum.performance,
            LighthouseStatEnum.accessibility,
            LighthouseStatEnum.seo,
            LighthouseStatEnum.bestPractices,
            LighthouseStatEnum.fcp,
            LighthouseStatEnum.speedIndex,
            LighthouseStatEnum.lcp,
            LighthouseStatEnum.blocking,
            LighthouseStatEnum.cls,
          ]
            .map((lighthouseStat) => ({
              lighthouseStat: lighthouseStat,
              ...getStats(lighthouseStat),
            }))
            .map((stat) => (
              <LighthousePerformanceStatsCard
                key={stat.lighthouseStat}
                lighthouseStat={stat.lighthouseStat}
                previousValue={stat.previousValue}
                currentValue={stat.currentValue}
              />
            ))}
        </div>
      </div>
    </PageSection>
  );
}

// ----------------------------------------------------------------------

type LighthousePerformanceStatsCardProps = {
  lighthouseStat: LighthouseStatEnum;
  previousValue: number | undefined;
  currentValue: number | undefined;
};

// ----------------------------------------------------------------------

function LighthousePerformanceStatsCard({
  lighthouseStat,
  previousValue,
  currentValue,
}: LighthousePerformanceStatsCardProps) {
  // HOOK
  const { translate } = useLocales();

  // VAR
  const lighthouseDisplayInfo = convertLighthouseStatsDisplay(
    lighthouseStat,
    previousValue,
    currentValue
  );
  const lighthouseStatusIcon = convertLighthouseStatusColorCodes(
    lighthouseStat,
    lighthouseDisplayInfo.convertedCurrentValue
  );

  return (
    <div className="gv-content-container gv-p-lg gv-flex-column-sm">
      <div className="gv-flex gv-flex-row gv-justify-between">
        <p className="gv-text-sm gv-text-bold">
          {translate(
            `wpone.sites.details.statistics.lighthousePerformance.infoCard.title.${lighthouseStat}`
          )}
        </p>
        <span style={{ color: `var(${lighthouseStatusIcon.color})` }}>
          <gv-icon src={`/src/icons/${lighthouseStatusIcon.iconName}.svg`} />
        </span>
      </div>

      {lighthouseDisplayInfo.convertedCurrentValueDisplay !== undefined ? (
        <div className="gv-flex gv-flex-row" style={{ gap: '4px' }}>
          <p className="gv-text-sm gv-text-bold">
            {lighthouseDisplayInfo.convertedCurrentValueDisplay}
          </p>
          {lighthouseDisplayInfo.valueDiffDisplay && (
            <p className="gv-text-sm gv-text-on-alternative">
              {lighthouseDisplayInfo.valueDiffDisplay}
            </p>
          )}
        </div>
      ) : (
        <p className="gv-text-sm gv-text-on-alternative">
          {translate('wpone.sites.details.statistics.lighthousePerformance.infoCard.noData')}
        </p>
      )}

      <p className="gv-text-sm gv-text-on-alternative">
        {translate(`wpone.sites.infoDescription.${lighthouseStat}`)}
      </p>
    </div>
  );
}
