import { ReactNode } from 'react';
import { useParams } from 'react-router';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  action?: ReactNode;
  description?: string | ReactNode;
  breadcrumbs?: boolean;
};

// ----------------------------------------------------------------------

export default function Header({ title, action, description, breadcrumbs = true }: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <div className="gv-flex-column-sm">
      {breadcrumbs && (
        <ul className="gv-breadcrumbs">
          <li>
            <a href="/sites">{translate('dashboard.sites.title')}</a>
            <gv-icon src="/src/icons/chevron_right.svg" />
          </li>
          <li>
            <span>{translate('dashboard.sites.details.mwp.sidebarTitle')}</span>
          </li>
        </ul>
      )}

      <div className="gv-flex gv-flex-column-sm gv-tab-gap-md">
        <div className="gv-flex-column-sm gv-tab-flex-row gv-tab-justify-between">
          <div className="gv-flex gv-flex-col">
            <p className="gv-heading-lg">{translate(title)}</p>
            <p className="gv-heading-sm gv-text-on-alternative">{name}</p>
          </div>

          {!isMobile && action && action}
        </div>

        {description &&
          (typeof description === 'string' ? (
            <p className="gv-text-sm gv-text-max">{translate(description)}</p>
          ) : (
            description
          ))}

        {isMobile && action && action}
      </div>
    </div>
  );
}
