import { useNavigate, useParams } from 'react-router';
// @types
import {
  PerformanceCardInfo,
  PerformanceCardTypeEnum,
  PerformanceColorCodeEnum,
} from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  score: number | undefined;
  colorCode: PerformanceColorCodeEnum;
  cardInfo: PerformanceCardInfo;
};

// ----------------------------------------------------------------------

export default function PerformanceCard({ score, colorCode, cardInfo }: Props) {
  const { name, cluster, namespace } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  // VAR
  const tempUserWpRocker: boolean = false;
  const tempUserRankMath: boolean = false;

  // EVENT FUNCTION
  function handleNavigateToPerformanceTab(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    // Prevent the default anchor click behavior
    e.preventDefault();

    navigate(`/sites/performance/${cluster}/${namespace}/${name}`);

    // Delay the smooth scrolling to ensure the navigation has completed
    setTimeout(() => {
      // Get the target element that the anchor link points to
      const target = document.getElementById(cardInfo.type);
      // Get the target element for site details performance page
      const pageTarget = document.getElementById('site_performance');

      if (target && pageTarget) {
        const targetPosition = target.getBoundingClientRect().top - pageTarget.offsetTop;

        window.scrollTo({
          top: targetPosition + window.scrollY,
          behavior: 'smooth',
        });
      }
    }, 100);
  }

  return (
    <div className="gv-card" style={{ background: 'var(--color-surface-bright)' }}>
      <div className="gv-card-content">
        <div className="gv-flex-column-md">
          <div
            className="gv-flex gv-justify-center gv-items-center gv-text-sm gv-text-bold"
            style={{
              width: '72px',
              height: '72px',
              borderRadius: '50%',
              background: `var(${
                colorCode === PerformanceColorCodeEnum.unknown
                  ? '--color-border'
                  : `--color-signal-${
                      colorCode === PerformanceColorCodeEnum.good
                        ? 'success-light'
                        : `${colorCode}-light`
                    }`
              })`,
              color: `var(${
                colorCode === PerformanceColorCodeEnum.unknown
                  ? '--color-body-on-alternative'
                  : `--color-signal-${
                      colorCode === PerformanceColorCodeEnum.good ? 'success' : `${colorCode}`
                    }`
              })`,
            }}
          >
            {score}
          </div>
          <div className="gv-flex gv-flex-col">
            <p className="gv-text-sm gv-text-bold">
              {translate(
                `dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.${cardInfo.type}.title`
              )}
            </p>
            <p className="gv-text-sm gv-text-on-alternative">
              {translate(cardInfo.description[colorCode])}
            </p>
          </div>
          {(colorCode === PerformanceColorCodeEnum.warning ||
            colorCode === PerformanceColorCodeEnum.alert) && (
            <a href="/#" className="gv-action" onClick={handleNavigateToPerformanceTab}>
              <div className="gv-flex gv-flex-row" style={{ gap: '4px' }}>
                <span
                  className="gv-text-sm gv-text-bold"
                  style={{ color: 'var(--color-state-form-on)' }}
                >
                  {translate(
                    `dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.action.${
                      (cardInfo.type === PerformanceCardTypeEnum.PERFORMANCE &&
                        !tempUserWpRocker) ||
                      (cardInfo.type === PerformanceCardTypeEnum.SEO && !tempUserRankMath)
                        ? 'improveNow'
                        : 'seeDetails'
                    }`
                  )}
                </span>
                <gv-icon src="/src/icons/arrow_forward.svg" />
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
