import { ReactNode } from 'react';
import { useParams } from 'react-router';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// components
import { Page } from 'src/components/gravity/page';
//
import Header from '../Header';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  stats: { iconName: string; text: string }[];
  description: ReactNode;
  children: ReactNode;
  backAction: VoidFunction;
};

// ----------------------------------------------------------------------

export default function UpdateActionPageLayout({
  title,
  stats,
  description,
  children,
  backAction,
}: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <Page title={`${name}`} noPadding>
      <div className="gv-breadcrumbs gv-mb-sm">
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            backAction();
          }}
          style={{ cursor: 'pointer' }}
        >
          <gv-icon src="/src/icons/arrow_back.svg" />
          <span>{translate('wpone.general.action.back')}</span>
        </a>
      </div>
      <div className="gv-flex gv-flex-col gv-gap-fluid">
        <div className="gv-flex-column-md">
          <Header title={title} breadcrumbs={false} />

          <div className="gv-flex-column-sm gv-tab-gap-md">
            <div
              className="gv-flex gv-flex-col gv-tab-flex-row"
              style={{
                gap: isMobile ? '4px' : '16px',
              }}
            >
              {stats.map((stat, index) => (
                <StatsDescrition key={index} text={stat.text} iconName={stat.iconName} />
              ))}
            </div>

            <p className="gv-text-sm gv-text-max">{translate(description)}</p>
          </div>
        </div>

        {children}
      </div>
    </Page>
  );
}

// ----------------------------------------------------------------------

type StatsDescriptionProps = {
  text: string;
  iconName: string;
};

// ----------------------------------------------------------------------

function StatsDescrition({ text, iconName }: StatsDescriptionProps) {
  return (
    <div
      className="gv-flex gv-flex-row gv-items-center gv-text-on-alternative"
      style={{ gap: '4px' }}
    >
      <gv-icon src={`/src/icons/${iconName}.svg`} />
      <p className="gv-text-sm gv-text-bold">{text}</p>
    </div>
  );
}
