import { Dispatch, SetStateAction } from 'react';
// @types
import { UpdateDetails, UpdateDetailsInfo, UpdateDetailsTypeEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import Indicator from 'src/components/gravity/Indicator';

// ----------------------------------------------------------------------

type Props = {
  row: UpdateDetailsInfo;
  setCurrentUpdateDetailsInfo: Dispatch<
    SetStateAction<undefined | { type: UpdateDetailsTypeEnum; details: UpdateDetails }>
  >;
};

// ----------------------------------------------------------------------

export default function UpdateReportTableRow({ row, setCurrentUpdateDetailsInfo }: Props) {
  // HOOK
  const { translate } = useLocales();

  // VAR
  const { type, details } = row;

  return (
    <tr>
      <td>{type === UpdateDetailsTypeEnum.CORE ? 'WordPress Core' : details.title}</td>
      <td>
        <Indicator
          type={details.reverted ? 'alert' : 'success'}
          text={translate(
            `wpone.sites.details.updates.updateReport.table.row.status.${
              details.reverted ? 'failed' : 'successful'
            }`
          )}
        />
      </td>
      <td>{translate(`wpone.sites.details.updates.type.${type}`)}</td>
      <td>
        <div className="gv-flex gv-flex-row gv-items-center" style={{ gap: '4px' }}>
          <p>{details.version_from}</p>
          <gv-icon src="/src/icons/arrow_forward.svg" size="small" />
          <p>{`${
            type !== UpdateDetailsTypeEnum.CORE && details.reverted
              ? details.version_attempted
              : details.version_to
          }${
            details.reverted
              ? ` (${translate(
                  'wpone.sites.details.updates.updateReport.table.row.version.failed'
                )})`
              : ''
          }`}</p>
        </div>
      </td>
      <td>
        {details.visual_diffs && (
          <a
            href="/#"
            className="gv-action gv-action-primary"
            onClick={(e) => {
              e.preventDefault();
              setCurrentUpdateDetailsInfo({
                type,
                details,
              });
            }}
            style={{ color: 'var(--color-state-form-on)' }}
          >
            <span className="gv-text-sm gv-text-bold">
              {translate('wpone.sites.details.updates.updateReport.table.row.action')}
            </span>
            <gv-icon src="/src/icons/arrow_forward.svg" />
          </a>
        )}
      </td>
    </tr>
  );
}
